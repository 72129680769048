<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche capacité technique :
          </span>
          <span style="font-size: 25px" class="font-weight-bold">{{
            CAPACITE_TECHNIQUE.name
          }}</span>
        </p>
        <div class="row mb-4">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h4 text-secondary my-auto mr-auto">
            Informations générales
          </p>
          <a
            v-if="!editMode && !disabled"
            class="text-secondary"
            style="font-size: 30px; color: #007bff !important"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div v-else-if="!disabled" class="m-0 d-flex p-0">
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="bg-secondary mb-3 mt-1" />
        </div>
        <div class="row" v-if="editMode">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Libellé"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newCapacite.name"
                :class="{
                  'is-invalid':
                    $v.newCapacite.name.$error && $v.newCapacite.name.$dirty,
                }"
                type="text"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.name.$dirty"
              >
                {{
                  !$v.newCapacite.name.required
                    ? "Champ obligatoire"
                    : !$v.newCapacite.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newCapacite.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Niveau"
              label-class="font-weight-bold"
            >
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.newCapacite.niveau.$error &&
                    $v.newCapacite.niveau.$dirty,
                }"
                v-model="newCapacite.niveau"
                type="number"
                disabled
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.niveau.$dirty"
              >
                {{ !$v.newCapacite.niveau.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Capacité rattachement : "
              label-class="font-weight-bold"
            >
              <v-select
                label="name"
                :reduce="(capacite) => capacite['@id']"
                v-model="newCapacite.parent"
                :options="capaciteRatechements"
                placeholder="Capacité rattachement"
                @input="newCapacite.niveau = newCapacite.parent ? 2 : 1"
              ></v-select>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Description"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                v-model="newCapacite.description"
                type="text"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Libellé : </span>
              <span class="font-weight-bold">
                {{ CAPACITE_TECHNIQUE.name }}
              </span>
            </div>
            <div class="my-4">
              <span>Niveau : </span>
              <span class="font-weight-bold">
                {{ CAPACITE_TECHNIQUE.niveau }}</span
              >
            </div>

            <div class="my-4">
              <span>Capacité rattachement : </span>
              <span class="font-weight-bold">
                {{
                  CAPACITE_TECHNIQUE.parent
                    ? CAPACITE_TECHNIQUE.parent.name
                    : "-"
                }}</span
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Description : </span>
              <span class="font-weight-bold">
                {{ CAPACITE_TECHNIQUE.description }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- {{ CAPACITE_TECHNIQUE.composants }} -->
      <div>
        <b-tabs class="tab-solid tab-solid-primary mt-3">
          <b-tab title="Composants techniques rattachés">
            <ComposantsTable
              :composants="CAPACITE_TECHNIQUE.composants"
              :disabled="disabled"
          /></b-tab>
          <b-tab title="Cadres rattachés"
            ><CadresTable
              :cadres="CAPACITE_TECHNIQUE.cadreEntreprises"
              :disabled="disabled" /></b-tab
        ></b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import CadresTable from "./components/CadresTable";
import ComposantsTable from "./components/ComposantsTable";

export default {
  components: {
    CadresTable,
    ModalActions,
    ComposantsTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newCapacite: {
      name: "",
      description: "",
      sousCapacites: [],
      composants: [],
      niveau: "",
    },
  }),
  validations: {
    newCapacite: {
      name: {
        required,
        minLength: minLength(5),
      },

      niveau: {
        required,
      },
    },
  },
  methods: {
    updateItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        delete this.newCapacite.composants;
        this.$store
          .dispatch("capaciteTechnique/updateCapacite", {
            id: this.$route.params.id,
            ...this.newCapacite,
            niveau: parseInt(this.newCapacite.niveau),
            sousCapacites: this.newCapacite.sousCapacites.map(
              (capacite) => capacite["@id"]
            ),
          })
          .then(() => {
            this.$store
              .dispatch(
                "capaciteTechnique/fetchCapacite",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("La capacité est mise à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },
  },
  created() {
    this.$store
      .dispatch("capaciteTechnique/fetchCapacite", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("composant/fetchAllComposants");
  },
  computed: {
    ...mapGetters("capaciteTechnique", [
      "CAPACITE_TECHNIQUE",
      "CAPACITES_TECHNIQUES",
    ]),
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("composant", ["COMPOSANTS"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),

    capaciteRatechements() {
      return this.CAPACITES_TECHNIQUES.filter(
        (capa) => capa.id != this.$route.params.id && capa.niveau === 1
      );
    },
    applicationsList() {
      return this.APPLICATIONS;
    },
    composantsList() {
      return this.COMPOSANTS;
    },
    disabled() {
      return !(this.isArchitect || this.isSuper || this.isAdmin);
    },
  },
  watch: {
    CAPACITE_TECHNIQUE: function () {
      this.newCapacite = {
        ...this.newCapacite,
        name: this.CAPACITE_TECHNIQUE.name,
        description: this.CAPACITE_TECHNIQUE.description,
        niveau: this.CAPACITE_TECHNIQUE.parent ? 2 : 1,
        // sousCapacites: this.CAPACITE_TECHNIQUE.sousCapacites,
        composants: this.CAPACITE_TECHNIQUE.composants,
        sousCapacites: this.CAPACITE_TECHNIQUE.sousCapacites,
        parent: this.CAPACITE_TECHNIQUE.parent
          ? this.CAPACITE_TECHNIQUE.parent["@id"]
          : null,
      };
    },
  },
};
</script>

<style></style>
